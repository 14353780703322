/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

//import css waiting list attraction mastery
import 'lazysizes'
import "bootstrap/dist/css/bootstrap.min.css"
import './src/assets/css/style-salespage.css'
import './src/assets/css/style_princing.css'